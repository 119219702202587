import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";

// Map.js
import React, { useEffect, useState, useRef } from "react";
import styles from "./Map.module.scss";

import FilterControl from "../Filter/FilterControl";

import { useGeoJsonData } from "../../GeoJsonDataContext";

import { INTRO_TEXT } from "../../settings";

// Device detection
import { isMobile } from "react-device-detect";

const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = () => {
  const { geoJsonData } = useGeoJsonData();

  const mapContainer = useRef(null);
  const infoContainer = useRef(null);

  const [map, setMap] = useState(null);
  const [styleLoading, setStyleLoading] = useState(true);
  const [iconsLoading, setIconsLoading] = useState(true);
  const [mapLoading, setMapLoading] = useState(true);

  const introduction = `
    <div class="${styles.icon} ${styles.group}">
      <img id=${styles.gemRuby} src="${MEDIA_URL}/img/WTD2023-Ruby.svg" alt="" role="presentation" />
      <img id=${styles.gemEmerald} src="${MEDIA_URL}/img/WTD2023-Emerald.svg" alt="" role="presentation" />
      <img id=${styles.gemDiamond} src="${MEDIA_URL}/img/WTD2023-Diamond.svg" alt="" role="presentation" />
    </div>
    <div class="${styles.messageContainer} ${styles.intro}">
      <p class=${styles.message}>${INTRO_TEXT}</p>
      <span class=${styles.sender}>NSW Education Standards Authority</span>
    </div>
  `;

  function getIcon(schoollevel) {
    if (schoollevel === "Early Childhood") {
      return `${MEDIA_URL}/img/emerald.png`;
    } else if (schoollevel === "Primary School") {
      return `${MEDIA_URL}/img/ruby.png`;
    } else if (schoollevel === "Secondary School") {
      return `${MEDIA_URL}/img/diamond.png`;
    } else {
      return `${MEDIA_URL}/img/amethyst.png`;
    }
  }

  function bufferBbox(bbox, buffer) {
    const [minLon, minLat, maxLon, maxLat] = bbox;

    return [
      minLon - buffer,
      minLat - (isMobile ? buffer + 2.5 : buffer),
      maxLon + buffer,
      maxLat + buffer,
    ];
  }

  useEffect(() => {
    // This effect will be called once when the component mounts and when geojsonData updates
    const initializeMap = ({ setMap, mapContainer }) => {
      // Bounding box for New South Wales (NSW)
      const nswBbox = [140.9993, -37.5051, 153.6388, -28.157];
      const bufferredBBox = bufferBbox(nswBbox, isMobile ? 2.2 : 5);
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/smallmultiples/clme8z9pl00n701pv7b87fzfw",
        center: isMobile
          ? [147.31905, -34.47493703000571]
          : [147.31905, -33.083640948961005], // Approximate center of NSW
        // zoom: 3, // Adjust this value as needed to show the entire state
        maxBounds: bufferredBBox,
      });

      // Animated icon
      const size = 200;
      // This implements `StyleImageInterface`
      // to draw a pulsing dot icon on the map.
      const animatedIcon = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
          const canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // Call once before every frame where the icon will be used.
        render: function () {
          const duration = 1000;
          const t = (performance.now() % duration) / duration;

          const radius = (size / 2) * 0.3;
          const outerRadius = (size / 2) * 0.7 * t + radius;
          const context = this.context;

          // Draw the outer circle.
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          context.fillStyle = `rgba(255, 243, 209, ${0.8 - t})`;
          context.fill();

          // Update this image's data with data from the canvas.
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // Continuously repaint the map, resulting
          // in the smooth animation of the dot.
          map.triggerRepaint();

          // Return `true` to let the map know that the image was updated.
          return true;
        },
      };

      map.on("style.load", function () {
        setStyleLoading(false);
      });

      map.on("load", () => {
        if (geoJsonData) {
          // debugger
          map.addImage("animatedDot", animatedIcon, { pixelRatio: 2 });

          // Add an event listener to change the cursor when hovering over symbols
          map.on("mouseenter", "symbols", function () {
            map.getCanvas().style.cursor = "pointer";
          });

          map.on("mouseleave", "symbols", function () {
            map.getCanvas().style.cursor = "";
          });

          // Initialize and add the dropdown control
          // const dropdownControl = new DropdownControl();
          // map.addControl(dropdownControl, 'top-right'); // You can choose the position

          const schools = new Set(); // For unique school names
          const postcodes = new Set(); // For unique postcodes

          geoJsonData.features.forEach((feature) => {
            schools.add(feature.properties.school);
            postcodes.add(feature.properties.postcode);
          });

          const createOption = (value) => ({
            value: value,
            label: value === "" ? "Not specified" : value,
          });

          // Map and sort school options
          const schoolOptions = Array.from(schools)
            .filter((item) => item)
            .map(createOption)
            .sort((a, b) => a.label.localeCompare(b.label));

          // Map and sort postcode options
          const postcodeOptions = Array.from(postcodes)
            .filter((item) => item)
            .map(createOption)
            .sort((a, b) => {
              // Check if both 'a.label' and 'b.label' are indeed strings before sorting
              if (typeof a.label === "string" && typeof b.label === "string") {
                return a.label.localeCompare(b.label);
              } else {
                // console.warn(
                //   `Skipping comparison for ${a} and ${b}. Expected strings but received ${typeof a} and ${typeof b} respectively.`
                // );
                return 0;
              }
            });

          // Combine into grouped options
          const options = [
            {
              label: "Schools",
              options: schoolOptions,
            },
            {
              label: "Postcodes",
              options: postcodeOptions,
            },
          ];

          options.unshift({ value: "all", label: "All Schools" }); // Add 'All Schools' option

          const handleInputChange = (selectedOption) => {
            if (!selectedOption || selectedOption.value === "all") {
              map.setFilter("messages", null); // If input is cleared, show all markers
            } else {
              map.setFilter("messages", [
                "any",
                ["==", ["get", "school"], selectedOption.value],
                ["==", ["get", "postcode"], selectedOption.value],
              ]);
              const visibleMessages = geoJsonData.features
                .filter(
                  (m) =>
                    m.properties.school === selectedOption.value ||
                    m.properties.postcode === selectedOption.value
                )
                .map((m) => [m.properties.longitude, m.properties.latitude]);
              // // Find bounds of filtered coordinates
              const bounds = new mapboxgl.LngLatBounds();
              if (visibleMessages.length > 0) {
                // debugger;
                visibleMessages.forEach((coord) => {
                  if (
                    Array.isArray(coord) &&
                    coord.length === 2 &&
                    typeof coord[0] === "number" &&
                    typeof coord[1] === "number"
                  ) {
                    // bounds.extend(coord);
                    bounds.extend({ lat: coord[1], lng: coord[0] });
                  }
                });
              }
              // Zoom map to fit bounds
              if (bounds && bounds._ne && bounds._sw) {
                map.fitBounds(bounds, { padding: 30, maxZoom: 11 });
              }
            }
          };
          const filterControl = new FilterControl(options, handleInputChange);
          map.addControl(filterControl, "top-left");

          var RetractableGeocoderControl = {
            onAdd: function (map) {
              this.map = map;
              this.container = document.createElement("div");
              this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

              var button = document.createElement("button");
              button.className = `mapboxgl-ctrl-icon mapboxgl-ctrl-search ${styles.searchButton}`;
              button.innerHTML =
                '<svg class="mapboxgl-ctrl-geocoder--icon mapboxgl-ctrl-geocoder--icon-search" width="20px" height="20px"><path d="M7.4 2.5c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9c1 0 1.8-.2 2.5-.8l3.7 3.7c.2.2.4.3.8.3.7 0 1.1-.4 1.1-1.1 0-.3-.1-.5-.3-.8L11.4 10c.4-.8.8-1.6.8-2.5.1-2.8-2.1-5-4.8-5zm0 1.6c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.3-1.3-3.3-3.1 1.4-3.3 3.3-3.3z"></path></svg>'; // Replace with an actual icon if desired

              // Add search (geocoding) control to the map
              const geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                placeholder: "Go to a location",
                bbox: nswBbox,
                marker: { color: "#F9AE2B" },
                countries: "AU",
              });

              // Listen to the 'results' event
              geocoder.on("results", function (event) {
                if (event.features && event.features.length > 3) {
                  // Limit the results to the top 3
                  event.features = event.features.slice(0, 3);
                }
              });

              this.container.appendChild(button);
              this.container.appendChild(geocoder.onAdd(map));
              // debugger
              geocoder.container.style.display = "none";
              geocoder.container.style.margin = 0;

              button.addEventListener("click", () => {
                if (geocoder.container.style.display === "none") {
                  geocoder.container.style.display = "block";
                  button.style.display = "none";
                  geocoder.container.focus();
                }
              });

              map.on("click", () => {
                if (geocoder.container.style.display === "block") {
                  geocoder.container.style.display = "none";
                  button.style.display = "block";
                }
              });

              return this.container;
            },

            onRemove: function () {
              this.container.parentNode.removeChild(this.container);
              this.map = undefined;
            },
          };

          const ResetViewControl = {
            onAdd: function (map) {
              this._map = map;
              this._btn = document.createElement("button");
              this._btn.type = "button";
              this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-reset"; // Assigning default classnames
              this._btn.title = "Reset view";
              this._btn.onclick = () => {
                map.flyTo({
                  center: isMobile
                    ? [147.31905, -34.47493703000571]
                    : [147.31905, -33.083640948961005], // Provide your initial center
                  zoom: isMobile ? 3.932423459616931 : 4.640339082781262, // Provide your initial zoom level
                });
              };
              this._btn.innerHTML = `<img alt='shape of NSW' width='22px' style='position:relative;top:2px;width:22px!important' src='${MEDIA_URL}/img/NSW-shape.svg'/>`; // You can also use an SVG or any other icon here

              this._container = document.createElement("div");
              this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
              this._container.appendChild(this._btn);

              return this._container;
            },

            onRemove: function () {
              this._container.parentNode.removeChild(this._container);
              this._map = undefined;
            },
          };
          map.addControl(ResetViewControl, "top-right"); // Adjust the position (e.g., 'top-right', 'bottom-left', etc.) as desired

          // Add zoom and rotation controls to the map.
          map.addControl(new mapboxgl.NavigationControl(), "top-right");

          map.addControl(
            RetractableGeocoderControl,
            isMobile ? "bottom-left" : "top-right"
          );

          setMapLoading(false);
        }
      });

      map.on("move", () => {
        setMap(map);
      });

      setMap(map);
    };

    if (!map && geoJsonData) initializeMap({ setMap, mapContainer });

    // return () => {
    //   if (map) {
    //     map.remove();
    //   }
    // };
  }, [map, geoJsonData]);

  useEffect(() => {
    function loadImage(name, src) {
      return new Promise((resolve, reject) => {
        map.loadImage(src, (error, image) => {
          if (error) {
            reject(error);
            return;
          }
          if (!map.hasImage(name)) {
            map.addImage(name, image);
          }
          resolve(name);
        });
      });
    }

    if (map) {
      Promise.all([
        loadImage("diamond", `${MEDIA_URL}/img/diamond.png`),
        loadImage("ruby", `${MEDIA_URL}/img/ruby.png`),
        loadImage("emerald", `${MEDIA_URL}/img/emerald.png`),
        loadImage("amethyst", `${MEDIA_URL}/img/amethyst.png`),
      ])
        .then(() => {
          // All icons have been loaded and added.
          //Now you can add layers or other tasks that depend on these images.
          setIconsLoading(false);
        })
        .catch((error) => {
          console.error("Error loading an image:", error);
        });
    }
  }, [map]);

  useEffect(() => {
    if (!iconsLoading && !styleLoading) {
      // Add geojson data as a new source to the map
      if (!map.getSource("messages")) {
        map.addSource("messages", {
          type: "geojson",
          data: geoJsonData,
        });
      }

      // Add a layer to use the symbols source with specific icons for each school level
      if (!map.getLayer("messages")) {
        map.addLayer({
          id: "messages",
          type: "symbol",
          source: "messages",
          layout: {
            "icon-image": [
              "match",
              ["get", "schoollevel"],
              "Early Childhood",
              "emerald",
              "Primary School",
              "ruby",
              // 3, 'diamond'
              [
                "case",
                ["all", ["==", ["get", "schoollevel"], "Secondary School"]],
                "diamond",
                "amethyst",
              ],
            ],
            // 'icon-image': 'diamond',
            "icon-size": [
              "interpolate",
              ["linear"],
              ["zoom"],
              1,
              0.1, // At zoom level 1, icon size is 0.5
              10,
              0.28, // At zoom level 10, icon size is 1
              20,
              0.7, // At zoom level 20, icon size is 2
            ],

            "icon-allow-overlap": true,
          },
        });
      }

      // Highlight layer for selected message markers
      if (!map.getLayer("highlight-layer")) {
        map.addLayer({
          id: "highlight-layer",
          type: "symbol",
          source: "messages",
          layout: {
            "icon-image": "animatedDot",
          },
          filter: ["==", "id", ""], // Initialize with an impossible filter so no features are shown
        });
      }

      // });

      map.on("click", function (e) {
        // Unselect marker
        if (!e.features || e.features.length === 0) {
          map.setFilter("highlight-layer", ["==", "id", ""]); // Reset the filter
          infoContainer.current.innerHTML = introduction;
        }
      });

      map.on("click", "messages", function (e) {
        // Ensure that we actually clicked on a feature in our symbols layer
        if (e.features.length > 0) {
          const feature = e.features[0];
          const featureId = feature.properties.id;
          const icon = getIcon(feature.properties.schoollevel);
          // const iconName = icon ? icon.split('img/')[1].replace('.png', '') : '';
          const messageWords = feature.properties.message.split(" ");
          let message = "";
          // Wrap each word in a span with a random delay
          for (let word of messageWords) {
            let randomDelay = Math.random() * 2; // random number between 0 and 2
            message += `<span class=${styles.word} style="--delay:${randomDelay}">${word}</span> `;
          }

          // Create content for the popup from the feature's properties
          let content = `
                            <img id="${styles.gemIcon}" class="${styles.icon} ${
            styles.single
          }" src=${icon} alt='' role="presentation" />
                            <div class=${styles.messageContainer}>
                              <p class=${styles.message}>${
            feature.properties.schoollevel !== "special"
              ? message
              : `${message} <br/><em>- ${feature.properties.author}</em>`
          }</p>
                              <span class=${styles.sender}>${
            feature.properties.school ? feature.properties.school : ""
          }</span>
                            </div>
                          `;
          infoContainer.current.innerHTML = content;
          // Set the filter for the highlight-layer to only show the feature with the clicked id
          map.setFilter("highlight-layer", ["==", "id", featureId]);
        }
      });
    }
  }, [iconsLoading, styleLoading, geoJsonData, introduction, map]);

  return (
    <div className={styles.Map}>
      <div className={styles.mapContainer} ref={mapContainer}></div>
      <div
        className={styles.infoContainer}
        ref={infoContainer}
        tabIndex="0"
        dangerouslySetInnerHTML={{ __html: introduction }}
      />
      {mapLoading && (
        <div className={styles.overlay}>
          <div className={styles.spinner}></div>
        </div>
      )}
    </div>
  );
};

export default Map;
