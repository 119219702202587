import { useEffect } from "react";

import Papa from "papaparse";
import { useGeoJsonData } from "./GeoJsonDataContext";
import {
  MESSAGES_DATA_URL,
  BBOX_DATA_URL,
  GEOLOCATION_DATA_URL,
  FIELD_PREFIX,
  UTF16,
} from "./settings";

const phyllotaxis_points = (num_points, center, spacing) => {
  let results = [];
  for (let i = 1; i <= num_points; i++) {
    let angle = i * (Math.PI * (3 - Math.sqrt(5)));
    let distance = spacing * Math.sqrt(i);
    let x = center[0] + Math.cos(angle) * distance;
    let y = center[1] + Math.sin(angle) * distance;
    results.push([x, y]);
  }
  return results;
};

const calculateBBox = (polygon) => {
  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity;
  polygon[0].forEach((coord) => {
    if (coord[0] < minX) minX = coord[0];
    if (coord[0] > maxX) maxX = coord[0];
    if (coord[1] < minY) minY = coord[1];
    if (coord[1] > maxY) maxY = coord[1];
  });
  return [minX, minY, maxX, maxY];
};

const bboxRadius = (bbox) => {
  const [minX, minY, maxX, maxY] = bbox;
  return Math.sqrt((maxX - minX) ** 2 + (maxY - minY) ** 2) / 2;
};

// const getPresetMessage = (ind) => {
//   if(ind >= PRESET_SHOUTOUTS.length) return null;
//   return PRESET_SHOUTOUTS[ind];
// }

const constructGems = (gems) => {
  const gemsArray = gems.split(";");
  // Handle edge cases
  if (!Array.isArray(gemsArray) || gemsArray.length === 0) return "";
  // Remove semicolons from each word
  const words = gemsArray
    .filter((word) => word.trim() !== "")
    .map((gem) => gem.replace(/;/g, ""));
  switch (words.length) {
    case 1: // When there's only one word in the list
      return words[0];
    case 2: // When there are two words in the list
      return words.join(" and ");
    default: // For three or more words in the list
      const lastWord = words.pop(); // Remove and save the last word from the list
      return `${words.join(", ")}, and ${lastWord}`; // Join remaining words with commas, then append the last word with 'and' in front
  }
};

const lowerCaseFirstLetter = (sentence) => {
  if (!sentence || typeof sentence !== "string") {
    return ""; // or you can return an error message
  }
  return sentence.charAt(0).toLowerCase() + sentence.slice(1);
};

const constructMessage = (preset, teacherTitle, teacherName, custom) => {
  const salutation = teacherTitle + " " + teacherName;
  const gems = constructGems(preset);
  const cleanedCustom = custom.replace(/�/g, "'");
  if (preset && custom) {
    // debugger
    return salutation + " " + gems + ". " + cleanedCustom;
  } else if (preset && !cleanedCustom) {
    return salutation + " " + gems + ".";
  } else if (!preset && cleanedCustom) {
    return salutation + ", " + lowerCaseFirstLetter(cleanedCustom);
  } else {
    return null;
  }
};

const convertDateStringToDate = (dateString) => {
  // Split the date and time parts
  const parts = dateString.split(" ");

  // Split and destructure the date part
  let [month, day, year] = parts[0].split("/");

  // Initialize default hour and minute values
  let hour = 0,
    minute = 0;

  // If time exists, split and destructure the time part
  if (parts[1]) {
    [hour, minute] = parts[1].split(":");
  }

  // If year is two digits, convert it to four digits
  if (year.length === 2) {
    year = parseInt(year, 10) + 2000;
  }

  // Create a new Date object
  // Note: JavaScript months are zero-based, so we need to subtract 1 from the month
  const date = new Date(
    year,
    parseInt(month, 10) - 1,
    parseInt(day, 10),
    parseInt(hour, 10),
    parseInt(minute, 10)
  );

  return date;
};

const csvToGeoJSON = (messages, geolocation, bboxPolygons) => {
  const messagesData = Papa.parse(messages, {
    header: true,
    skipEmptyLines: true,
    transformHeader: function (header) {
      // Removing newline characters
      var cleanedHeader = header.replace(/\r\n/g, "").trim();
      return cleanedHeader;
    },
  }).data;
  const geolocationData = Papa.parse(geolocation, {
    header: true,
    skipEmptyLines: true,
  }).data;

  const getSchoolName = function (entry) {
    return entry[FIELD_PREFIX + "Corrected School Name"]
      ? entry[FIELD_PREFIX + "Corrected School Name"].trim()
      : entry[FIELD_PREFIX + "School name (optional)"].trim();
  };

  const validateSchoolName = function (name) {
    // if (name === "2190") debugger;
    const isNumber = /^\d+$/.test(name);
    const hasAlphabetChars = /[a-zA-Z]/.test(name);
    return !isNumber || hasAlphabetChars;
  };
  const jsonData = messagesData
    .filter((entry) => {
      // const safe = entry[FIELD_PREFIX + "Classification"] === "safe";
      const publish =
        entry[FIELD_PREFIX + "Publish?"].toLowerCase().trim() === "y";
      const validSchoolName = validateSchoolName(getSchoolName(entry));
      return validSchoolName && publish;
    })
    .map((entry, index) => {
      const id = index;
      // debugger
      const postcodeID =
        entry[
          FIELD_PREFIX +
            "To put your message on a map, what's the postcode for the school?"
        ].trim();
      const location = postcodeID
        ? geolocationData.find(
            (location) => location[FIELD_PREFIX + "POA_CODE21"] === postcodeID
          )
        : null;
      const school = getSchoolName(entry);
      // const presetshoutout = entry[FIELD_PREFIX+"presetshoutout"];
      const schoollevel = entry[FIELD_PREFIX + "Teacher's school level"].trim();
      const custom =
        entry[FIELD_PREFIX + "Write a custom message (optional)"].trim();
      const preset = entry[FIELD_PREFIX + "My teaching gem (teacher)"].trim();
      const teacherTitle = entry[FIELD_PREFIX + "Teacher's title"].trim();
      const teacherName = entry[FIELD_PREFIX + "Teacher's surname"].trim();

      const date = entry[FIELD_PREFIX + "Completion time"].trim();

      const obj = {
        id,
        postcode: location ? location[FIELD_PREFIX + "POA_CODE21"] : null,
        unique_id: id,
        longitude: location
          ? parseFloat(location[FIELD_PREFIX + "longitude"])
          : null,
        latitude: location
          ? parseFloat(location[FIELD_PREFIX + "latitude"])
          : null,
        message: constructMessage(preset, teacherTitle, teacherName, custom), // add presetshoutout
        school,
        schoollevel,
        date: date ? convertDateStringToDate(date) : null,
      };
      return obj;
    });
  // Group by postcode
  const groupedEntries = {};

  jsonData.forEach((entry) => {
    // debugger
    const key = entry.postcode
      ? `${entry.postcode}-${entry.latitude}-${entry.longitude}`
      : "No location";
    if (!groupedEntries[key]) {
      groupedEntries[key] = [];
    }
    groupedEntries[key].push(entry);
  });

  const features = [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [147, -32.5],
      },
      properties: {
        id: "special",
        message:
          "I wouldn’t be where I am today without the support of my English teacher, Mrs Elborough. She encouraged my love of English and my interest in current affairs. She changed my life forever.",
        author:
          "The Hon. Prue Car MP, Deputy Premier and Minister for Education and Early Learning",
        school: "Caroline Chisholm College",
        schoollevel: "special",
        postcode: 2745,
        date: convertDateStringToDate("10/9/24 00:00:00"),
      },
    },
  ];

  // eslint-disable-next-line no-unused-vars
  for (const [_, entries] of Object.entries(groupedEntries)) {
    const [firstEntry] = entries;
    const center = [
      parseFloat(firstEntry.longitude),
      parseFloat(firstEntry.latitude),
    ];

    // Calculate the Phyllotaxis point positions for entries in this group
    const matchingPolygon = bboxPolygons.features.find((polygon) => {
      return polygon.properties.SAL_NAME21 === firstEntry.suburb;
    });
    const bbox = matchingPolygon
      ? calculateBBox(matchingPolygon.geometry.coordinates)
      : null;
    const radius = bbox ? bboxRadius(bbox) : null;
    const spacing = radius ? radius / Math.sqrt(entries.length) : 0.01;

    const points = phyllotaxis_points(entries.length, center, spacing);

    points.forEach((point, index) => {
      const entry = entries[index];
      features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: point,
        },
        properties: {
          ...entry,
          schoollevel: parseInt(entry.schoollevel)
            ? parseInt(entry.schoollevel)
            : entry.schoollevel,
          // "suburb": entry.suburb
        },
      });
    });
  }

  const geoJson = {
    type: "FeatureCollection",
    features: features,
  };
  return geoJson;
};

async function fetchData() {
  const [messagesResponse, geolocationResponse, polygonResponse] =
    await Promise.all([
      fetch(MESSAGES_DATA_URL),
      fetch(GEOLOCATION_DATA_URL),
      fetch(BBOX_DATA_URL),
    ]);

  // Decoder for MS Power Apps CSV export
  const csvTextDecoder = new TextDecoder(UTF16 ? "utf-16" : "utf-8");

  // Messages
  const messagesArrayBuffer = await messagesResponse.arrayBuffer();
  const messages = csvTextDecoder.decode(messagesArrayBuffer);
  // Geolocation correspondences
  const geolocationArrayBuffer = await geolocationResponse.arrayBuffer();
  const geolocation = csvTextDecoder.decode(geolocationArrayBuffer);

  const bboxPolygons = await polygonResponse.json();

  return { messages, geolocation, bboxPolygons };
}

export const DataLoaderHelper = () => {
  const { setGeoJsonData } = useGeoJsonData();

  useEffect(() => {
    const loadData = async () => {
      const { messages, geolocation, bboxPolygons } = await fetchData();
      const geoJson = csvToGeoJSON(messages, geolocation, bboxPolygons);
      setGeoJsonData(geoJson);
    };

    loadData();
  }, [setGeoJsonData]);

  return null; // This component does not render anything itself
};
