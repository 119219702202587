import React, { useEffect, useRef } from "react";
import Select from "react-select";
import styles from "./Filter.module.scss";

const Filter = ({ options, onInputChange }) => {
  const controlRef = useRef();

  useEffect(() => {
    // Implement any additional setup or cleanup here
    return () => {
      // Implement any cleanup here
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: "160px",
      overflow: "hidden",
    }),
  };

  return (
    <div ref={controlRef} className="mapboxgl-ctrl">
      <Select
        options={options}
        onChange={onInputChange}
        isSearchable={true}
        isClearable={true}
        placeholder="Search by school/postcode"
        className={styles.Filter}
        styles={customStyles}
      />
    </div>
  );
};

export default Filter;
