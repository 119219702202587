export const INTRO_TEXT =
  "Welcome to our interactive map celebrating World Teachers’ Day in NSW! Dive into a heartwarming collection of messages from students across the state of New South Wales. Zoom in and click on a gem today to read all the tributes and memories shared. Celebrate your teaching gem by pinning a gem to our interactive map this World Teachers’ Day.";

// export const MESSAGES_DATA_URL = MEDIA_URL+"/data/cr225_shoutoutses.csv";
// export const MESSAGES_DATA_URL = process.env.REACT_APP_MEDIA_URL+"/data/NESA World Teachers Day 2023 Shout-Out Form 1.csv";
export const MESSAGES_DATA_URL =
  process.env.REACT_APP_MEDIA_URL + "/data/published_submissions.csv";

// export const FIELD_PREFIX = "cr225_";
export const FIELD_PREFIX = "";

export const PRESET_SHOUTOUTS = [
  "for your deep knowledge",
  "for your sense of humour",
  "for your support",
];

// export const GEOLOCATION_DATA_URL = MEDIA_URL+"/data/cr225_tables.csv";
// export const BBOX_DATA_URL = MEDIA_URL+"/data/nsw_sal_bboxes.geojson";
export const GEOLOCATION_DATA_URL =
  process.env.REACT_APP_MEDIA_URL + "/data/nsw_postcode_centroids.csv";
export const BBOX_DATA_URL =
  process.env.REACT_APP_MEDIA_URL + "/data/nsw_postcode_bboxes.geojson";

export const UTF16 = false;
